.divider {
    margin: 0;
    flex-shrink: 0;
    border-width: 0;
    border-style: solid;
    border-color: $dividerColor;
    border-bottom-width: thin;
    &#{&}-absolute {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    &#{&}-light {
        border-color: rgba($dividerColor, 0.08);
    }

    &#{&}-inset {
        margin-left: 72px;
    }
    &#{&}-middle {
        .divider-horizontal {
            margin-left: 8 * 2px;
            margin-right: 8 * 2px;
        }
        .divider-vertical {
            margin-top: 8 * 1px;
            margin-bottom: 8 * 1px;
        }
    }
    &#{&}-vertical {
        height: 100;
        border-bottom-width: 0;
        border-right-width: 1.5px;
    }

    //flexItem
    &#{&}-flex-item {
        align-self: stretch;
        height: auto;
    }

    &#{&}-text-align-right {
        &::not(.divider-wrapper-vertical) {
            &::before {
                width: 90%;
            }
            &::after {
                width: 10%;
            }
        }
    }
    &#{&}-text-align-left {
        &::not(.divider-wrapper-vertical) {
            &::before {
                width: 10%;
            }
            &::after {
                width: 90%;
            }
        }
    }
    // children
    &#{&}-wrapper {
        display: flex;
        white-space: nowrap;
        text-align: center;
        border: 0;
        &::before,
        &::after {
            content: '';
            position: relative;
            width: 100%;
            border-top: thin solid $dividerColor;
            top: 50%;
            transform: translateY(50%);
        }
        &#{&}-vertical {
            flex-direction: column;
            &::before,
            &::after {
                height: 100%;
                top: 0%;
                left: 50%;
                border-top: 0;
                border-left: thin solid $dividerColor;
                transform: translateX(0%);
            }
        }
    }
}

.divider-wrapper .divider-wrapper {
    display: inline-block;
    padding-left: 8 * 1.2px;
    padding-right: 8 * 1.2px;
    &.divider-wrapper-vertical {
        padding-top: 8 * 1.2px;
        padding-bottom: 8 * 1.2px;
    }
}
