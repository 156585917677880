$listPadding: 16px;
$listPaddingTopBottom: calc($listPadding/2);
$listItemDense: calc($listPaddingTopBottom/2);
$listGutters: $listPadding;
$_listItemButton: '.list-item-button';

.list {
    list-style: none;
    margin: 0;
    padding: 8px 0;
    position: relative;
    border: 1px solid $borderColor;
    &#{&}-disable-padding {
        padding: 0;
    }
    &#{&}-subheader {
        padding-top: 0;
    }
    &#{&}-sub {
        background-color: $backgroundDefault;
        > .list-item-button {
            padding-left: 52px;
        }
    }
}

.list-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    padding: $listPaddingTopBottom 0;
    &#{&}-disable-padding {
        padding-top: 0;
        padding-bottom: 0;
    }

    &.list-item-gutters {
        padding-left: $listGutters;
        padding-right: $listGutters;
    }

    &:not(#{&}-disable-padding) {
        &.list-item-dense {
            padding-top: $listItemDense;
            padding-bottom: $listItemDense;
        }
    }

    &#{&}-flex-start {
        align-items: flex-start;
    }

    &#{&}-disabled {
        opacity: 0.38;
    }

    &#{&}-divider {
        border-bottom: 1px solid $dividerColor;
        background-clip: padding-box;
    }
}

div#{$_listItemButton} {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    text-decoration: none;
    box-sizing: border-box;
    text-align: left;
    padding: $listPaddingTopBottom 0;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover {
        text-decoration: none;
        background-color: rgba($primaryColorMain, 0.5);
        // Reset on touch devices, it doesn't add specificity
        @media (hover: none) {
            background-color: transparent;
        }
    }

    &#{$_listItemButton}-gutters {
        padding-left: $listGutters;
        padding-right: $listGutters;
    }

    &#{$_listItemButton}-disabled {
        opacity: 0.38;
    }

    &#{$_listItemButton}-selected {
        background-color: $primaryColorMain;
        color: #fff;
        &:hover {
            background-color: rgba($primaryColorMain, $activeSelectedOpacity + 0.5);
        }
    }
}

a#{$_listItemButton} {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    text-decoration: none;
    box-sizing: border-box;
    text-align: left;
    padding: $listPaddingTopBottom 0;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover {
        text-decoration: none;
        // background-color: theme.palette.action.hover,
        background-color: rgba(0, 0, 0, 0.04);
        // Reset on touch devices, it doesn't add specificity
        @media (hover: none) {
            background-color: transparent;
        }
    }

    &#{$_listItemButton}-gutters {
        padding-left: $listGutters;
        padding-right: $listGutters;
    }

    &#{$_listItemButton}-disabled {
        opacity: 0.38;
    }

    &#{$_listItemButton}-selected {
        background-color: $primaryColorMain;
        color: #fff;
        &:hover {
            background-color: rgba($primaryColorMain, $activeSelectedOpacity + 0.5);
        }
    }
}

.list-item-text {
    flex: 1 1 auto;
    min-width: 0;
    margin-top: 4px;
    margin-bottom: 4px;
    > [class^='typography'][class$='-primary'],
    > [class^='typography'][class$='-secondary'] {
        margin: 0;
    }
    > [class$='-secondary'] {
        color: rgba(0, 0, 0, 0.6);
    }
    &#{&}-inset {
        padding-left: 56px;
    }
    &#{&}-group {
        margin-top: 6px;
        margin-bottom: 6px;
    }
}

.list-item-icon {
    min-width: 36px;
    color: rgba(0, 0, 0, 0.54);
    flex-shrink: 0;
    display: inline-flex;
    &#{&}-margin-top {
        margin-top: 8px;
    }
}
