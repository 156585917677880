.body-composition-table {
  font-size: 1.6rem;

  .table-cell {
    padding: 10px 15px;
  }

  .table-head {
    .table-cell {
      background-color: $backgroundLight;
    }
  }

  .table-body {
    > .table-row:nth-child(even) {
      > .table-cell {
        background-color: $backgroundLight;
      }
    }
  }
}
