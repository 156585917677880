.body-type-box {
  position: relative;
  //background-color: white;
  padding: 0 2rem;

  &__wrap {
    position: relative;
  }

  &__img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }

  &__map {
    position: absolute;
    top: 0;
    right: 0;
    width: 83%;
    height: 89%;
  }

  &__position {
    --size: 17px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: var(--size);
    height: var(--size);
    border-radius: var(--size);
    border: 4px solid $primaryColorLight;
    transform: translate(-50%, 50%);
    background: $primaryColorMain;
  }
}
