//$fontFamily,$htmlFontSize ref _default.scss
@mixin mxBuildVariant($_fontWeight, $_fontSize, $_lineHeight, $_letterSpacing, $_caseAllCaps: false) {
    font-weight: $_fontWeight;
    font-size: $_fontSize;
    line-height: $_lineHeight + em;
    font-family: $fontFamily;
    letter-spacing: calc($_letterSpacing / calc(calc($htmlFontSize/1px) * calc($_fontSize/1rem))) + em;
    @if $_caseAllCaps {
        text-transform: uppercase;
    }
}
// h1 h2 h3 h4 h5 h6 subtitle1 subtitle2 body1 body2 button Ref _variableMap.scss
@mixin mxVariants($key: 'body1') {
    @include mxBuildVariant(map-get($typographyVariantsMap, $key)...);
}

@mixin mxMainColors($colorString) {
    color: map-get($colorMainMap, $colorString);
}

// 可以控制寬度的虛線 例如 size 8px => gray-400 4px rgba(0,0,0,0) 4px
// 設定在 ::before ::after 對齊自己設定
@mixin mxFakeDashed($color: $gray400, $size: 6px, $direction: 'row') {
    background-image: linear-gradient(to #{map-get($directionMap, $direction)}, #{$color} 0%, #{$color} 50%, rgba(0, 0, 0, 0) 50%);
    background-size: #{$size} #{$size};
    @if $direction == 'col' {
        height: 100%;
        width: 1px;
        background-repeat: repeat-y;
    }
    @if $direction == 'row' {
        height: 1px;
        width: 100%;
        background-repeat: repeat-x;
    }
}

@mixin mxTringle-custom($tringle-color, $tringle-width) {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: $tringle-width;
        border-color: $tringle-color;
        @content;
    }
}

@mixin mxTringle-shadow($tringle-shadow, $tringle-width) {
    &::before {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: $tringle-width;
        border-color: $tringle-shadow;
        @content;
    }
}

@mixin mxTringle-up($color, $size, $rate) {
    $up-color: transparent transparent $color transparent;
    $up-shadow: transparent transparent rgba(0, 0, 0, 0.2) transparent;
    $up-size: 0 $size ($size * $rate) $size;
    @include mxTringle-custom($up-color, $up-size) {
        bottom: -($size * $rate) * -1;
        left: 50%;
        margin-left: math.div(($size * $rate), 2) * -1;
        @content;
    }

    @include mxTringle-shadow($up-shadow, $up-size) {
        bottom: (($size * $rate) - 1) * -1;
        left: 50%;
        margin-left: math.div(($size * $rate), 2) * -1;
        @content;
    }
}
