// 曲線變化評估
.measurement-body-chart {
  --contentWidth: 115px;
  background-color: #E1D8E9;

  & &__container {
    position: relative;
    padding: 20px;
  }

  & &__image {
    position: absolute;
    top: 50%;
    right: 124px;
    transform: translateY(-50%);
    height: calc(100% - 40px);

    > img {
      height: 100%;
      width: auto;
    }
  }

  & &__content {
    width: var(--contentWidth);
    margin-left: auto;
  }

  & &__line-group {
    position: absolute;
    top: 0;
    right: 134px;
    width: 23%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    gap: 10px;
    padding: 20px 0;
  }

  & &__line {
    border: 1px dashed rgba(black, .2);
    width: 100%;
    transform-origin: center right;

    &:nth-child(1) {
      width: 115%;
      transform: rotate(-30deg);
    }

    &:nth-child(2) {
      width: 66%;
    }

    &:nth-child(3) {
      width: 90%;
      transform: rotate(25deg);
    }

    &:nth-child(4) {
      width: 110%;
      transform: rotate(48deg);
    }

    &:nth-child(5) {
      width: 130%;
      transform: rotate(58deg);
    }

    &:nth-child(6) {
      width: 155%;
      transform: rotate(60deg);
    }

    &.highlight {
      border-color: #66437E;
    }
  }

  .measurement-item {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: $paperBorderRadius;
    border: 1px solid rgba(black, .1);
    padding: 10px;
    margin-bottom: 15px;
    background-color: white;

    &:last-child {
      margin-bottom: 0;
    }

    &__title {
      font-size: 1.4rem;
      margin-bottom: 0;
    }

    &__value {
      font-size: 1.2em;
      font-weight: bold;
    }

    &.highlight {
      background-color: #66437E;
      color: white;

      .measurement-item__title {
        color: white;

      }
    }
  }

  @media screen and (min-width: 430px) {
    & &__image {
      right: 165px;
    }

    & &__line-group {
      width: 30%;
    }

    & &__line {
      &:nth-child(1) {
        width: 108%;
        transform: rotate(-22deg);
      }

      &:nth-child(2) {
        width: 79%;
      }

      &:nth-child(3) {
        transform: rotate(19deg);
      }

      &:nth-child(4) {
        width: 104%;
        transform: rotate(34deg);
      }

      &:nth-child(5) {
        width: 118%;
        transform: rotate(45deg);
      }

      &:nth-child(6) {
        width: 133%;
        transform: rotate(49deg);
      }
    }
  }
}

.measurement-limb-muscles {
  --contentWidth: 115px;
  background-color: #E1D8E9;

  & &__container {
    position: relative;
    padding: 20px;
  }

  & &__image {
    position: absolute;
    top: 50%;
    right: 124px;
    transform: translateY(-50%);
    height: calc(100% - 40px);
  }

  & &__image-wrap {
    position: relative;
    width: 100%;
    height: 100%;

    .img-wrap {
      height: 100%;
    }

    img {
      height: 100%;
      width: auto;
    }

    .overlay-img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  & &__content {
    width: var(--contentWidth);
    height: 423px;
    padding: 20px 10px;
    margin-left: auto;
    border-radius: $paperBorderRadius;
    font-size: 18px;
    background-color: white;
    text-align: center;

    p {
      margin-bottom: 20px;
    }
  }

  @media screen and (min-width: 430px) {
    & &__image {
      right: 165px;
    }
  }
}




.body-table {
  .highlight {
    color: white;
    background-color: #66437E;
    padding: 2px 12px;
    border-radius: 30px;
  }
}


