/* WIDTH */
.w-auto {
    width: auto !important;
}
.w-25 {
    width: 25% !important;
}
.w-50 {
    width: 50% !important;
}
.w-75 {
    width: 75% !important;
}
.w-100 {
    width: 100% !important;
}
.vw-50 {
    width: 50vw !important;
}
.wx-40rem {
    max-width: 40rem !important;
}
.wx-65rem {
    max-width: 65rem !important;
}
.wx-90rem {
    max-width: 90rem !important;
}
.wx-120rem {
    max-width: 120rem !important;
}

/* HEIGHT */
.h-auto {
    height: auto !important;
}
.h-25 {
    height: 25% !important;
}
.h-50 {
    height: 50% !important;
}
.h-75 {
    height: 75% !important;
}
.h-100 {
    height: 100% !important;
}
