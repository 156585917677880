.main-container {
    padding-top: 57px;
}

.container {
    width: 100%;
    max-width: $mobile-container-width;
    margin: 0 auto;
}

.container-full {
    width: 100%;
    margin: 0 auto;
    overflow: auto;
    iframe {
        border: 0;
    }
}

.page-center {
    text-align: center;
    
    img {
        width: 100%;
    }
}