.footer {
  text-align: center;
  border-top: 1px solid $gray100;
  margin-top: 20px;

  .container {
    padding: 15px 0;
  }

  .copyright {
    font-size: 1.4rem;
    text-align: center;
    line-height: 1.6;
  }
}