.login {
  &-page {
    //官網漸層色
    color: white;
    background: linear-gradient(90deg, #662d8c 1.71%, rgba(105, 44, 142, 0.87) 26.66%, rgba(113, 42, 148, 0.79) 42.47%, rgba(128, 37, 160, 0.73) 55.74%, rgba(149, 31, 175, 0.66) 67.6%, rgba(176, 23, 195, 0.61) 78.53%, rgba(209, 14, 220, 0.56) 88.77%, rgba(247, 2, 249, 0.51) 98.3%, rgba(255, 0, 255, 0.5) 100%);
  }

  &-form {
    position: relative;
    text-align: center;
    padding: 0 2rem 2rem;
    z-index: 20;

    > div:not(last-child) {
      margin-bottom: 1.6rem;
    }

    .formlabel {
      text-align: center;
      font-size: $fontSize;
      color: white;
      margin-bottom: 5px;
    }

    .asterisk-text {
      color: white;
    }

    input {
      font-size: 2.4rem;
      text-align: center;
    }

    button {
      font-size: 2rem;
      margin-top: 20px;
      height: 52px;
      width: 200px;
      background-color: white;
    }

    .button-root:hover:not(.button-disabled).button-variant-outlined.button-color-primary {
      background-color: white;
    }
  }

  &-body {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 86px);
    padding-top: 40px;

    .logo-wrap {
      max-width: 250px;
      margin: 0 auto 30px;

      > img {
        width: 100%;
        height: auto;
        vertical-align: middle;
      }
    }
  }

  &-body-container {
    max-width: 450px;
    margin: 0 auto;
  }

  &-btn-confirm {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer {
    border-top-color: rgba(white, .3);

    .copyright {
      color: rgba(white, .8);
    }
  }
}

