.header {
  position: fixed;
  width: 100%;
  background: white;
  z-index: $appBarZIndex;
  margin: 0;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }

  &--logo {
    width: 100px;

    > img {
      width: 100%;
      height: auto;
    }
  }

  &--user {
    flex: auto;
    text-align: right;
    padding: 0 5px;

    img {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      border: 1px solid $gray300;
    }

    span {
      font-size: 16px;
      padding: 0 5px;
      font-weight: bold;
    }
  }
}

@media screen and (orientation: landscape) {
  .header {
    position: absolute;
  }
}