$_notFoundPage: ".not-found-page";
$startCount: 80;

@function fnRandom($a, $b) {
    @return floor(random(($b - $a + 1) + $a));
}

@keyframes blink {
    0%,
    100% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(0.4);
        opacity: 0.5s;
    }
}

@keyframes floating {
    0%,
    100% {
        transform: translate(#{fnRandom(-3, 3)}px, #{fnRandom(-3, 3)}px) rotate(#{fnRandom(-2, 2)}deg);
    }
    20% {
        transform: translate(#{fnRandom(-3, 3)}px, #{fnRandom(-3, 3)}px) rotate(#{fnRandom(-2, 2)}deg);
    }
    40% {
        transform: translate(#{fnRandom(-3, 3)}px, #{fnRandom(-3, 3)}px) rotate(#{fnRandom(-2, 2)}deg);
    }
    60% {
        transform: translate(#{fnRandom(-3, 3)}px, #{fnRandom(-3, 3)}px) rotate(#{fnRandom(-2, 2)}deg);
    }
    80% {
        transform: translate(#{fnRandom(-3, 3)}px, #{fnRandom(-3, 3)}px) rotate(#{fnRandom(-2, 2)}deg);
    }
}

#{$_notFoundPage}-root {
    background-image: linear-gradient(to right bottom, #a9347e, #4b32a7);
    width: 100%;
    height: 100vh;
    overflow: hidden;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0.2px;
    color: white;
    @at-root #{$_notFoundPage}-star {
        position: absolute;
        z-index: 1;
    }
    @at-root #{$_notFoundPage}-center {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        @media screen and (max-height: 600px) {
            transform: translate(-50%, -50%) scale(0.75);
        }
        @media screen and (max-width: 400px) {
            transform: translate(-50%, -50%) scale(0.6);
        }
    }
    @at-root #{$_notFoundPage}-circle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
    }
    @at-root #{$_notFoundPage}-circle-outer {
        width: 550px;
        height: 550px;
        background-image: linear-gradient(to right bottom, #802b89, #5631a1);
        box-shadow: 0px 0px 20px -8px black;
    }
    @at-root #{$_notFoundPage}-circle-inner {
        width: 355px;
        height: 355px;
        background-image: linear-gradient(to right bottom, #802b89, #5631a1);
        box-shadow: 0px 0px 20px -8px black;
    }
    @at-root #{$_notFoundPage}-four {
        position: absolute;
        font-size: 150px;
        font-weight: 700;
        text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
        user-select: none;
        &.four-1 {
            top: 50%;
            left: 30px;
            transform: translateY(-50%);
            z-index: 1;
        }
        &.four-2 {
            top: 50%;
            right: 30px;
            transform: translateY(-50%);
            z-index: 3;
        }
    }
    @at-root #{$_notFoundPage}-oh {
        z-index: 2;
        animation: floating 4s linear infinite;
        transition: all 0.1s linear;
    }
}

// ********************SORRY START********************
#{$_notFoundPage}-sorry {
    position: absolute;
    top: 210px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 15px;
}
// ********************SORRY END********************

// ********************PLANET START********************
.planet {
    background-color: #ccc;
    width: 155px;
    height: 155px;
    box-shadow: inset -6px -10px 0px 1px #aaa, 0px 2px 10px rgba(0, 0, 0, 0.4);
}
.crater {
    $craterPosList: (
            "w": 35px,
            "h": 35px,
            "t": 30px,
            "f": 25px,
        ),
        (
            "w": 23px,
            "h": 23px,
            "t": 20px,
            "f": 93px,
        ),
        (
            "w": 17px,
            "h": 17px,
            "t": 77px,
            "f": 17px,
        ),
        (
            "w": 10px,
            "h": 10px,
            "t": 90px,
            "f": 80px,
        ),
        (
            "w": 25px,
            "h": 25px,
            "t": 115px,
            "f": 50px,
        ),
        (
            "w": 13px,
            "h": 13px,
            "t": 118px,
            "f": 105px,
        );
    position: absolute;
    border-radius: 50%;
    background-color: #aaa;
    @for $i from 1 through length($craterPosList) {
        &.crater-#{($i)} {
            $mapGet: nth($craterPosList, $i);
            width: map-get($mapGet, w);
            height: map-get($mapGet, h);
            top: map-get($mapGet, t);
            left: map-get($mapGet, f);
        }
    }
}
// ********************PLANET END********************

// ********************ASTRONAUT START********************
.astronaut {
    position: absolute;
    top: -115px;
    left: -30px;
    transform: rotate(17deg);
    .astronaut-backpack {
        width: 100px;
        height: 100px;
        background-color: #e3e3e3;
        border-radius: 10px;
    }
    .astronaut-helmet {
        height: 66px;
        width: 66px;
        border-radius: 50%;
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        z-index: 3;
        overflow: hidden;
        box-shadow: 0px 5px 1px -3px gray;
        .astronaut-glass {
            position: absolute;
            background: #00202e;
            top: 13px;
            left: 5px;
            height: 56px;
            width: 56px;
            border-radius: 10px;
        }
        .glow {
            position: absolute;
            border-radius: 50%;
            background-color: #325f7b;
            &.glow-1 {
                top: 25px;
                right: 15px;
                width: 17px;
                height: 17px;
            }
            &.glow-2 {
                top: 44px;
                right: 14px;
                width: 7px;
                height: 7px;
            }
        }
    }
    .astronaut-antena {
        position: absolute;
        top: -7px;
        &.antena-left {
            left: 10px;
            .antena {
                left: 5px;
            }
            .antena-tip {
                left: 3px;
            }
        }
        &.antena-right {
            right: 10px;
            .antena {
                left: 8px;
            }
            .antena-tip {
                left: 6px;
            }
        }
        .antena-tip {
            position: absolute;
            top: -33px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #c3c3c3;
        }
        .antena {
            position: absolute;
            top: -29px;
            width: 2px;
            height: 30px;
            border-radius: 5px;
            background-color: #c3c3c3;
        }
        .ear-up {
            position: relative;
            width: 15px;
            height: 20px;
            border-radius: 5px;
            background-color: #c3c3c3;
            z-index: 2;
        }
        .ear-down {
            position: absolute;
            bottom: -5px;
            width: 15px;
            height: 20px;
            border-radius: 5px;
            background-color: #afafaf;
            z-index: 1;
        }
    }
    .astronaut-body {
        position: absolute;
        top: 30px;
        left: 50%;
        width: 50px;
        height: 50px;
        background-color: #c3c3c3;
        transform: translateX(-50%);
    }
}
// ********************ASTRONAUT END********************

// ********************ASTRONAUT-HAND START********************
.astronaut-hands {
    position: absolute;
    top: -89px;
    left: -23px;
    width: 100px;
    transform: rotate(17deg);
    z-index: 3;
    .astronaut-hand {
        position: absolute;
        &.hand-left {
            left: -3px;
        }
        &.hand-right {
            right: -3px;
            transform: scaleX(-1);
        }
    }
}
// ********************ASTRONAUT-HAND END********************

// ********************ASTRONAUT-WIRE START********************
.astronaut-wire {
    position: absolute;
    top: -40px;
    left: -260px;
}
// ********************ASTRONAUT-WIRE END********************
@for $i from 1 through $startCount {
    $size: fnRandom(1, 4);
    #{$_notFoundPage}-star:nth-child(#{$i}) {
        top: #{fnRandom(0, 99)}vh;
        left: #{fnRandom(0, 99)}vw;
        &:before,
        &:after {
            position: absolute;
            content: "";
            background-color: white;
            border-radius: 10px;
            animation: blink 1.5s infinite;
            animation-delay: (#{$i * -1}s);
        }
        &:before {
            top: (#{calc($size / 2)}px);
            left: (-#{calc($size / 2)}px);
            width: (#{calc(3 * $size)}px);
            height: #{$size}px;
        }
        &:after {
            top: (-#{calc($size / 2)}px);
            left: (#{calc($size / 2)}px);
            width: #{$size}px;
            height: (#{calc(3 * $size)}px);
        }
    }
}
