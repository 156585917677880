
.measurement {
  &-title {
    font-size: $H6FontSize;
    font-weight: bold;
    color: $gray700;
    padding: 20px 16px;
    margin: 0;

    .measurement-transcript & {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &-group {
    background-color: white;
    margin-bottom: 8px;
    border-radius: $paperBorderRadius;

    .body-composition-table {
      &.elevation-1 {
        box-shadow: none;
      }
    }

    .measurement-item {
      border-top: 1px solid $backgroundLight;
      padding: 14px 20px 16px;

      &:last-child {
        border-bottom: 1px solid $backgroundLight;
      }
    }
  }
}

.measurement-menu {
  position: sticky;
  //width: 100%;
  top: 57px;
  background: white;
  box-shadow: map-get($shadows, 4);
  z-index: $speedDialZIndex;

  &--container {
    max-width: $mobile-container-width;
    margin: 0 auto;

    .tab-root {
      color: $fontColor1;
      opacity: .5;

      &:hover {
        color: $fontColor1;
        opacity: 1;
      }

      &.tab-selected {
        color: $primaryColorMain;
        opacity: 1;
      }
    }

    .tabs-indicator {
      height: 3px;
    }
  }
}


.inbody-score-box + .tip {
  margin-top: 8px;
  color: $fontColor3;
}



@media screen and (orientation: landscape) {
  .measurement-menu {
    position: absolute;
    left: 0;
    right: 0;
  }

  .measurement-menu + .container {
    margin-top: 57px;
  }
}
