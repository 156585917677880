.body-table {
  background-color: white;
  font-size: 1.6rem;

  .row-title {
    color: white;
    background-color: #B59DC4;
  }

  .table-cell {
    border-bottom: rgba(black, .1) 1px solid;
    padding: 10px 15px;
  }

  .table-head {
    display: none;
  }

  .table-body {
    .table-row {
      display: block;

      .table-cell {
        position: relative;
        display: block;

        &[data-title] {
          position: relative;
          padding-left: 50%;

          &::before {
            content: attr(data-title);
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            padding: 10px 15px;
            width: calc(50% - 1.2rem);
            background-color: #E5DBEB;
            box-sizing: border-box;
          }
        }

      }
    }
  }
}