.measurement-date-controller {
  position: relative;
  border-radius: 50px;
  overflow: hidden;
  background-color: white;
  text-align: center;

  &__button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    &:hover {
        background: transparent;
      .svg-icon-root {
        color: $primaryColorMain;
      }
    }

    &--prev {
        left: 0;
    }

    &--next {
        right: 0;
    }

    &.icon-button.icon-button-disabled {
      color: inherit;
      opacity: .5;
    }
  }

  &__select {
    width: calc(100% - 92px);

    .select-icon {
      display: none;
    }
  }
}