.measurement-bar {
  --bar-height: 10px;
  --bar-radius: 5px;
  position: relative;
  background-color: #f0f0f0;
  margin-bottom: 4px;

  &__background {
    position: relative;
    width: 100%;
    height: var(--bar-height);
    border-radius: var(--bar-radius);
    overflow: hidden;
    background-color: $gray200;
  }

  &__sections-wrap {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: var(--bar-height);
  }

  &__section {
    --line-height: 4px;
    position: relative;
    height: 100%;
    flex: 1;

    & + & {
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 100%;
            width: 1px;
            height: var(--line-height);
            background-color: $gray200;
        }
    }

    &:nth-child(3),
    &:nth-child(5) {
        &::after {
            top: 0;
            width: 1px;
            height: calc(100% + (var(--line-height) * 2));
            background-color: $gray200;
        }
    }
  }

  &__foreground {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__labels {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    font-size: 1.2rem;
    margin-top: 12px;
    transform: translateY(-50%);
    color: $gray500;
  }

  &__label {
    text-align: center;

    &--left {
      flex: 2;
    }

    &--middle {
      flex: 2;
    }

    &--right {
      flex: 6;
    }
  }
}
