* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    font-size: $htmlFontSize;
    font-family: $fontFamily;
    color: $fontColor1;
    font-weight: $fontWeightRegular;
}

body {
    // -webkit-font-smoothing: antialiased; //ipad use
    -webkit-text-size-adjust: 100%;
    font-size: $fontSize;
    background-color: $backgroundPage;
    color: $textPrimaryColor;
    line-height: 1.2;
}

/* H1 ~ H6 Style */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
    color: $titleColor;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    margin-top: 0.875rem * 2;
    margin-bottom: 0.875rem;
    line-height: $titleLineHeight;
    font-weight: $fontWeightBold;
}

h4,
h5,
h6 {
    padding: 0.875rem 0;
    line-height: $titleLineHeight;
}

h1,
.h1 {
    font-size: $H1FontSize;
}

h2,
.h2 {
    font-size: $H2FontSize;
}

h3,
.h3 {
    font-size: $H3FontSize;
}

h4,
.h4 {
    font-size: $H4FontSize;
}

h5,
.h5 {
    font-size: $H5FontSize;
}

h6,
.h6 {
    font-size: $H6FontSize;
}

hr {
    border-color: transparent;
    border-bottom: 1px solid #c3dcd1;
    border-radius: 2px;
}

p {
    line-height: $lineHeight;
    margin: 0 0 0.875rem;
}

a {
    line-height: $titleLineHeight;
    text-decoration: none;
    color: $textPrimaryColor;
    &:hover {
        text-decoration: none;
        color: $primaryColorMain;
    }
}

strong {
    font-weight: $fontWeightBold;
}

input,
textarea,
select {
    font-family: $fontFamily;
    font-weight: $fontWeightRegular;
}

button {
    // line-height: 1.4em;
    font-family: $fontFamily;
    font-weight: $fontWeightRegular;
}

label {
    -webkit-tap-highlight-color: transparent;
}

textarea:disabled,
input:disabled {
    cursor: not-allowed;
}

ol {
    list-style: decimal;
    padding-left: 3rem;
    line-height: $lineHeight;
    li {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
    }
}

small {
    font-size: .7em;
}

sup {
    font-size: .7em;
    vertical-align: super !important;
}

sub {
    font-size: .7em;
    vertical-align: sub !important;
}

.hide {
    display: none !important;
}

.guidance-notes-title {
    .icon {
        margin-right: 1rem;
    }
}
