.part-analysis-box {
  position: relative;
  padding: 2rem;
  background: #e8dded;

  &__img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  &__data-item {
    flex: 1 1 50%;
    text-align: center;
    > p {
      margin: 0;
    }

    &.abdomen {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}