$_raido: '.radio';
#{$_raido} {
    color: $textSecondaryColor;
    &:hover {
        background-color: rgba($actionActiveColor, $actionHoverOpacity);
        @each $key, $value in $colorMainMap {
            &#{&}-color-#{$key} {
                background-color: rgba($value, $actionHoverOpacity);
            }
        }
        @media (hover: none) {
            background-color: transparent;
        }
    }
    @at-root #{$_raido}-checked {
        @each $key, $value in $colorMainMap {
            &#{$_raido}-color-#{$key} {
                color: $value;
            }
        }
    }
    @at-root #{$_raido}-disabled {
        color: $textDisabledColor;
    }
}
