/*
    文字對齊
 */
$alignMap: center, justify, left, right;
.text {
    @each $align in $alignMap {
        &-#{$align} {
            text-align: $align !important;
        }
    }
}

/*
    字重
 */
.font-weight-normal {
    font-weight: $fontWeightRegular !important;
}
.font-weight-medium {
    font-weight: $fontWeightMedium !important;
}
.font-weight-bold {
    font-weight: $fontWeightBold !important;
}

.font-weight-900 {
    font-weight: 900 !important;
}

.white-space-nowrap {
    white-space: nowrap !important;
}

.vertical-align-top {
    vertical-align: top !important;
}

.text-wrap {
    text-wrap: wrap !important;
}
