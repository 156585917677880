.MuiPaper-root.dialog-pdf {
    background: gray(300);
}

.dialog-pdf-close-icon {
    margin-left: auto;
}

.MuiDialog-container .MuiDialog-paper {
    background-color: $backgroundPaper;
    .MuiDialogContent-root {
        padding: 2.4rem;
        color: $fontColor1;
        background-color: $backgroundPaper;
    }
    .MuiDialogActions-root.MuiDialogActions-spacing {
        padding: 0 2.4rem 1.6rem;
        color: $fontColor1;
        background-color: $backgroundPaper;
    }
}
