.disease-risk-assessment-table {

  > .table-body {
    .table-cell {
      text-align: center;
      border: 1px solid #E5DBEB;
    }
  }


  & &__title {
    > .table-cell {
      padding: 8px 1.2rem;
      color: white;
      background-color: #B59DC4;
      > small {
        font-size: .7em;
        display: block;
      }
    }
  }

  & &__subtitle {
    > .table-cell {
      background-color: #E5DBEB;
    }
  }


  & &__icon {
    > :not(.highlight) {
      opacity: .5;
      filter: grayscale(1);
    }
    small {
      font-size: .7em;
      color: $fontColor3;
    }

    .icon {
      width: 44px;
    }
  }
}