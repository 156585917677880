$_skeleton: '.skeleton';

#{$_skeleton}-root {
    display: block;
    background-color: rgba($textPrimaryColor, 0.18); // alpha
    height: 1.2em;
    @at-root #{$_skeleton}-text {
        margin-top: 0;
        margin-bottom: 0;
        height: auto;
        transform-origin: 0 55%;
        transform: scale(1, 0.6);
        border-radius: #{$shapeBorderRadius} / #{calc(math.round(calc(calc($shapeBorderRadius / 0.6) * 10)) / 10)};
        &:empty:before {
            content: '\00a0';
        }
    }
    @at-root #{$_skeleton}-circular {
        border-radius: 50%;
    }
    @at-root #{$_skeleton}-children {
        visibility: hidden;
        &#{$_skeleton}-no-width {
            max-width: fit-content;
        }
        &#{$_skeleton}-no-height {
            height: auto;
        }
    }
    @at-root #{$_skeleton}-animation-pulse {
        animation: pulseKeyframe 1.5s ease-in-out 0.5s infinite;
    }
    @at-root #{$_skeleton}-animation-wave {
        position: relative;
        overflow: hidden;
        /* Fix bug in Safari https://bugs.webkit.org/show_bug.cgi?id=68196 */
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        &::after {
            animation: waveKeyframe 1.6s linear 0.5s infinite;
            background: linear-gradient(90deg, transparent, $actionHoverColor, transparent);
            content: '';
            position: absolute;
            transform: translateX(-100%); /* Avoid flash during server-side hydration */
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
        }
    }
}

@keyframes pulseKeyframe {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
}

@keyframes waveKeyframe {
    0% {
        transform: translateX(-100%);
    }

    50% {
        /* +0.5s of delay between each loop */
        transform: translateX(100%);
    }

    100% {
        transform: translateX(100%);
    }
}
