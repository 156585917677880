.loading-root {
  user-select: none;

  svg path,
  svg rect {
    fill: $primaryColorMain;
  }
}


.loading-sports-root {
  user-select: none;
  width: 100%;
  padding: 10px 0;
  overflow: hidden;

  .loading-sports-item {
    -webkit-animation: loading-sports-action 1s steps(4) both infinite;
    animation: loading-sports-action 1s steps(4) both infinite;
    -webkit-transition: all 2s;
    transition: all 2s;
    transform: scale(.9);
  }

  .loading-sports-item-1 {
    background: url('https://curvesstorage.blob.core.windows.net/commoncontainer/Sprites/css_sprites1.png') 0 0 no-repeat;
    width: 344px;
    height: 670px;
    margin: 0 auto;
  }

  .loading-sports-item-2 {
    background: url('https://curvesstorage.blob.core.windows.net/commoncontainer/Sprites/css_sprites2.png') 0 0 no-repeat;
    width: 540px;
    height: 670px;
    margin: 0 auto;
  }

  .loading-sports-item-3 {
    background: url('https://curvesstorage.blob.core.windows.net/commoncontainer/Sprites/css_sprites3.png') 0 0 no-repeat;
    width: 743px;
    height: 670px;
    margin: 0 auto;
  }
}

@keyframes loading-sports-action {
  100% {
    background-position: 0 100%;
  }
}


.loading-text {
  letter-spacing: 4px;
  text-align: center;
  color: $primaryColorDarken;
  
  > span {
    display: inline-block;
  }
}

.loading-text::after {
  content: '';
  display: inline-block;
  text-align: left;
  width: 40px;
  margin-left: 4px;
  animation: loading-dots-action 1.5s steps(4, end) infinite;
}

@keyframes loading-dots-action {
  0%, 20% {
    content: '';
  }
  40% {
    content: '.';
  }
  60% {
    content: '..';
  }
  80%, 100% {
    content: '...';
  }
}