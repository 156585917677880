.tabs-root {
    overflow: hidden;
    min-height: 48px;
    // Add iOS momentum scrolling for iOS < 13.0
    -webkit-overflow-scrolling: touch;
    display: flex;
    @at-root #{&}-vertical {
        flex-direction: column;
    }
    .scroll-buttons-hide-mobile {
        display: none;
    }
}

.tabs-scroller {
    position: relative;
    display: inline-block;
    flex: 1 1 auto;
    white-space: nowrap;
    @at-root #{&}-fixed {
        overflow-x: hidden;
        width: 100%;
    }
    @at-root #{&}-hide-scrollbar {
        // Hide dimensionless scrollbar on MacOS
        scrollbar-width: none; // Firefox
        &::-webkit-scrollbar {
            display: none; // Safari + Chrome
        }
    }
    @at-root #{&}-scrollable-x {
        overflow-x: auto;
        overflow-y: hidden;
    }
    @at-root #{&}-scrollable-y {
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.tabs-flex-container {
    display: flex;
    @at-root #{&}-fixed {
        overflow-x: hidden;
        width: 100%;
    }
    @at-root #{&}-scrollable-x {
        overflow-x: auto;
        overflow-y: hidden;
    }
    @at-root #{&}-scrollable-y {
        overflow-y: auto;
        overflow-x: hidden;
    }
    #{&}-vertical {
        flex-direction: column;
    }
    #{&}-centered {
        justify-content: center;
    }
    .svg-icon-root {
        margin-right: 0.8rem;
    }
}

.tabs-indicator {
    position: absolute;
    height: 2px;
    bottom: 0;
    width: 100%;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    @at-root #{&}-color-primary {
        background-color: $primaryColorMain;
    }
    @at-root #{&}-color-secondary {
        background-color: $secondaryColorMain;
    }
    #{&}-vertical {
        height: 100%;
        width: 2px;
        right: 0;
    }
}

.tabs-scrollbar-size {
    overflow-x: auto;
    overflow-y: hidden;
    // Hide dimensionless scrollbar on MacOS
    scrollbar-width: none; // Firefox
    &::-webkit-scrollbar {
        display: none; // Safari + Chrome
    }
}

$_tabScrollButton: '.tabs-scroll-button';
#{$_tabScrollButton} {
    width: 40px;
    flex-shrink: 0;
    opacity: 0.8;
    #{&}-disabled {
        opacity: 0;
    }
    #{&}-vertical {
        width: 100%;
        height: 40px;
        & svg {
            transform: rotate(90deg);
        }
        &#{$_tabScrollButton}-rtl svg {
            transform: rotate(-90deg);
        }
    }
}
