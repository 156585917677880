.evaluation-summary-table {

  > .table-body {
    .table-cell {
      text-align: center;
      border: 1px solid #E5DBEB;

      &.highlight {
        position: relative;

        > span {
          position: relative;
          color: white;
          z-index: 1;
        }
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: calc(100% - 2.4rem);
          height: calc(100% - 2rem);
          background-color: #66437E;
          border-radius: 999px;
        }
      }
    }
  }

  & &__title {
    > .table-cell {
      color: white;
      background-color: #B59DC4;
    }
  }

  & &__subtitle {
    > .table-cell {
      background-color: #E5DBEB;
    }
  }
}