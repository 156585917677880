$_select: ".select";
$_selectIcon: ".select-icon";
$_selectInput: ".select-input";

#{$_select}-root {
    -moz-appearance: none; // Reset
    -webkit-appearance: none; // Reset
    // When interacting quickly; the text can end up selected.
    // Native select can't be selected either.
    user-select: none;
    border-radius: 0; // Reset
    cursor: pointer;
    padding-right: 3rem;
    &:focus {
        background-color: rgba(0, 0, 0, 0.05);
    }
    // Remove IE11 arrow
    &::-ms-expand {
        display: none;
    }
    &#{$_select}-disabled {
        cursor: default;
    }
    &[multiple] {
        height: auto;
    }
    &:not([multiple]) option,
    &:not([multiple]) optgroup {
        background-color: $backgroundPaper;
    }
    &#{$_select}-outlined {
        border-radius: $shapeBorderRadius;
    }
    em{
        color: gray(200);
    }
}

#{$_select} {
    height: auto; // Resets for multiple select with chips
    min-height: 1.4375em; // Required for select\text-field height consistency
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 100px;
}

#{$_selectIcon} {
    position: absolute;
    right: 7px;
    // top: calc(100% - 0.5em);
    pointer-events: none;
    transition: 0.2s ease-in-out;
    @at-root #{$_selectIcon}-disabled {
        color: $actionDisabledColor;
    }
    @at-root #{$_selectIcon}-open {
        transform: rotate(180deg);
        transition: 0.2s ease-in-out;
    }
}

#{$_selectInput} {
    bottom: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    width: 100%;
    box-sizing: border-box;
}
