@import "../variables/defaults";

.limit-text {
    font-size: 1rem;
    float: right;
    padding: 12px;
    align-self: center;

    strong {
        font-weight: $fontWeightBold;
    }

    span {
        color: gray(500);
    }
}

.label-box-border-transition {
    -webkit-transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
    transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
}

.label-box {
    position: relative;
    margin-bottom: 1.6rem;

    #{&}-title {
        padding-top: 15px;
        display: inline-block;
    }

    > label {
        display: block;
        color: gray(300);
        padding-bottom: 1.2rem;
        font-size: 1.4rem;
        word-break: keep-all;
    }

    .text-tip {
        color: gray(800);
        font-size: 0.8rem;
    }

    .text-warnning-tip {
        color: $errorColorMain;
        font-size: 0.8rem;
    }
}

.label-box-required {
    color: $errorColorDarken;
    margin-left: 1.2rem;
}

.label-box-control {
    width: 100%;
    height: 3.0625rem;
    padding: 6px 20px;
    border: 1px solid $primaryColorMain;
    background-color: #fff;
    font-size: 1.4rem;
    line-height: 2;
    border-radius: 15px;
    @extend .label-box-border-transition;

    &:focus {
        border-color: $secondaryColorMain;
        box-shadow: 0 0 0.0625rem 0.0625rem $secondaryColorMain inset;
        outline: 0;
    }

    &.label-box-error {
        color: $errorColorMain;
        border-color: $errorColorMain;

        + .label-box-error {
            margin-top: 4px;
        }
    }
    &-limit-text {
        @extend .limit-text;
    }
}

.label-box-daterange {
    display: flex;
    div {
        flex: 1;
        margin-top: -6px;
        margin-bottom: -6px;
    }
    // icon
    .icofont {
        flex-basis: 20px;
        font-size: 1.72rem;
        line-height: 2;
        text-align: center;
    }
}

.label-box-datepicker {
    position: relative;
    input[type="text"] {
        width: 100%;
        height: 2.9rem;
        padding-left: 6px;
        border: 3px solid transparent;
        background-color: transparent;
        font-size: 16px;
        line-height: 1.6;
        @extend .label-box-border-transition;
        cursor: pointer;
    }
    &.active {
        @include mxTringle-up(#fff, 10px, 1.2) {
            z-index: $modalZIndex;
        }

        input[type="text"] {
            border-bottom-color: $primaryColorMain;
            outline: 0;
            box-shadow: 0;
        }
    }

    .icon-prefix {
        position: absolute;
        z-index: 1;
        top: 14px;
        left: 0px;
    }
}
