.float-button {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: $speedDialZIndex;
  background: $secondaryColorMain;
  box-shadow: map-get($shadows, 5);
  &:hover {
    background: $secondaryColorDarken;
  }
}